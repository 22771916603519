import React, { useEffect, useState, useCallback } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,
            text: 'Gráfico de Conversas',
            position: 'left',
        },
    },
};

const ChartsDate = () => {
    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [], count: 0 });
    const companyId = localStorage.getItem("companyId");

    const fetchTicketsData = useCallback(async () => {
        try {
            const formats = {
                initialDate: initialDate ? format(initialDate, 'yyyy-MM-dd') : '',
                finalDate: finalDate ? format(finalDate, 'yyyy-MM-dd') : '',
            };
            const response = await api.get(`/dashboard/ticketsDay`, {
                params: {
                    initialDate: formats.initialDate,
                    finalDate: formats.finalDate,
                    companyId,
                },
            });

            setTicketsData(response.data);
        } catch (error) {
            console.error('Error fetching ticket data:', error);
            toast.error('Erro ao buscar informações dos tickets');
        }
    }, [initialDate, finalDate, companyId]);

    useEffect(() => {
        fetchTicketsData();
    }, [fetchTicketsData]);

    const dataCharts = {
        labels: ticketsData?.data.map(item => item.horario ? `Das ${item.horario}:00 às ${item.horario}:59` : item.data),
        datasets: [
            {
                data: ticketsData?.data.map(item => item.total),
                backgroundColor: '#2DDD7F',
            },
        ],
    };

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total ({ticketsData?.count})
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={setInitialDate}
                        label="Início"
                        renderInput={(params) => <TextField {...params} fullWidth sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={setFinalDate}
                        label="Fim"
                        renderInput={(params) => <TextField {...params} fullWidth sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <Button
                    className="buttonHover"
                    variant="contained"
                    onClick={fetchTicketsData}
                >
                    Filtrar
                </Button>
            </Stack>

            <Bar options={chartOptions} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px' }} />
        </>
    );
}

export default ChartsDate;
