import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
        },
        title: {
            display: true,
            text: 'Total de Conversas por Usuários',
            position: 'top',
            font: {
                size: 18,
                weight: 'bold',
            },
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    const { dataset, raw } = context;
                    return `${dataset.label}: ${raw}`;
                },
            },
        },
        datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            color: '#000',
            font: {
                size: 14,
                weight: 'bold',
            },
            formatter: (value) => value?.toLocaleString(),
        },
    },
    scales: {
        x: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Usuários',
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
        },
        y: {
            beginAtZero: true,
            title: {
                display: true,
                text: 'Número de Tickets',
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
        },
    },
};

export const ChartsUser = () => {
    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({
        data: [
            {
                quantidade: 0,
                nome: '',
                tickets: {
                    closed: 0,
                    open: 0,
                    userId: 0,
                    interesse: 0,
                },
            },
        ],
    });

    const companyId = localStorage.getItem('companyId');

    const handleGetTicketsInformation = async (signal) => {
        try {
            const formats = {
                initialDate: initialDate ? format(initialDate, 'yyyy-MM-dd') : '',
                finalDate: finalDate ? format(finalDate, 'yyyy-MM-dd') : '',
            };

            const { data } = await api.get('/dashboard/ticketsUsers', {
                params: {
                    initialDate: formats.initialDate,
                    finalDate: formats.finalDate,
                    companyId: companyId || '', // Handle possible null companyId
                },
                signal, // Attach the abort signal to the request
            });

            if (!signal.aborted && data) {
                setTicketsData(data);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error(error);
                toast.error('Erro ao obter informações da conversa');
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        handleGetTicketsInformation(controller.signal);

        return () => {
            controller.abort(); // Cleanup: abort the request if the component unmounts
        };
    }, [initialDate, finalDate, companyId]);

    const dataCharts = {
        labels: ticketsData?.data?.map((item) => item?.nome || 'Desconhecido'),
        datasets: [
            {
                label: 'Tickets Fechados',
                data: ticketsData?.data?.map((item) => item?.tickets?.closed || 0),
                backgroundColor: '#FF5733',
            },
            {
                label: 'Tickets Sem Tag',
                data: ticketsData?.data?.map((item) => item?.tickets?.open || 0),
                backgroundColor: '#2DDD7F',
            },
            {
                label: 'Tickets Com interesse',
                data: ticketsData?.data?.map((item) => item?.tickets?.interesse || 0),
                backgroundColor: '#3498db',
            },
            {
                label: 'Total de tickets',
                data: ticketsData?.data?.map((item) => item?.quantidade || 0),
                backgroundColor: '#2d3a4f',
            },
        ],
    };

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total de Conversas por Usuários
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => setInitialDate(newValue)}
                        label="Início"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => setFinalDate(newValue)}
                        label="Fim"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <Button className="buttonHover" onClick={() => handleGetTicketsInformation()} variant="contained">
                    Filtrar
                </Button>
            </Stack>

            <Bar options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px' }} />
        </>
    );
};

export default ChartsUser;
