import React, { useEffect, useState, useCallback } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import './button.css';
import { TagsFilter } from '../../components/TagsFilter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,
            text: 'Tags distribuidas por Tickets',
            position: 'left',
        },
    },
};

const ChartsTicketTags = () => {
    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const companyId = localStorage.getItem("companyId");

    const fetchTicketsData = useCallback(async () => {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
            const formats = {
                initialDate: initialDate ? format(initialDate, 'yyyy-MM-dd') : '',
                finalDate: finalDate ? format(finalDate, 'yyyy-MM-dd') : '',
            };
            const response = await api.get(`/dashboard/ticketsTag`, {
                params: {
                    initialDate: formats.initialDate,
                    finalDate: formats.finalDate,
                    tagId: JSON.stringify(selectedTags || ''),
                    companyId,
                },
                signal,
            });

            if (!signal.aborted) {
                setTicketsData(response.data.data);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching ticket data:', error);
                toast.error('Erro ao buscar informações dos tickets');
            }
        }

        return () => controller.abort();
    }, [initialDate, finalDate, selectedTags, companyId]);

    useEffect(() => {
        const controller = new AbortController();
        fetchTicketsData();

        return () => {
            controller.abort();
        };
    }, [fetchTicketsData]);

    // Group the tags by name and sum their quantities
    const groupedTags = ticketsData.reduce((acc, item) => {
        const tagName = item.tagName || "Sem Tag";
        if (acc[tagName]) {
            acc[tagName] += Number(item.total);
        } else {
            acc[tagName] = Number(item.total);
        }
        return acc;
    }, {});

    // Extract labels and data for the chart
    const labels = Object.keys(groupedTags);
    const data = Object.values(groupedTags);

    const dataCharts = {
        labels,
        datasets: [
            {
                label: 'Quantidade de Tickets',
                data,
                backgroundColor: '#2DDD7F',
            },
        ],
    };

    const handleSelectedTags = (selecteds) => {
        const tags = selecteds.map(t => t.id);
        setSelectedTags(tags);
    };

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total de Tags por ticket ({ticketsData.length})
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center" sx={{ my: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <TagsFilter onFiltered={handleSelectedTags} />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={initialDate}
                        onChange={setInitialDate}
                        label="Início"
                        renderInput={(params) => <TextField {...params} fullWidth sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={setFinalDate}
                        label="Fim"
                        renderInput={(params) => <TextField {...params} fullWidth sx={{ width: '20ch' }} />}
                    />
                </LocalizationProvider>

                <Button
                    className="buttonHover"
                    variant="contained"
                    onClick={fetchTicketsData}
                >
                    Filtrar
                </Button>
            </Stack>

            <Bar options={chartOptions} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px' }} />
        </>
    );
};

export default ChartsTicketTags;
